
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import UrlRedirectResolver from "@/utilities/UrlRedirectResolver";
import { useStore } from "vuex";

export default {
  name: "BvrCheckoutErrorPage",
  components: {
    Header,
    Footer,
  },
  setup() {
    const store = useStore();

    const onBackToStoreClick = () => {
      const redirectUrl = store.getters[Getters.BVR__GET_CHECKOUT_DATA].failureRedirectUrl;
      UrlRedirectResolver.redirectToUrl(redirectUrl);
    };

    return {
      onBackToStoreClick
    };
  },
};
